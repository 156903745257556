<template>
  <div class="packingWork">
    <div class="breadcrumb_box">
      <Breadcrumb>
        <BreadcrumbItem v-for="(item, index) in breadcrumbList" :key="index" :to="item.path ? item.path : ''">{{ item.name }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 当前拣货单 -->
    <div class="pickHeader clearfix">
      <div>
        <Row>
          <Col span="15">
            <div style="fontSize:16px;marginLeft:10px;height: 40px;">
              {{ $t('key1003753') }}<span style="font-weight: bold;">{{ pickingGoodsNo }} {{ packageGoodsTypeName }}</span>
            </div>
          </Col>
          <Col span="9">
            <div class="fr" style="display: inline-block;float: right;margin-right: 10px;">
              <div style="fontSize:16px;marginLeft:10px;font-weight: bold;color: #fff;height: 40px;display: inline-block">
                {{ $t('key1003754') }}{{ userId }}
              </div>
              <Button size="small" style="marginLeft:30px;" @click="quitWork">{{ $t('key1003755') }}</Button>
              <Button size="small" style="marginLeft:30px;" @click="finishWork">{{ $t('key1003726') }}</Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <!-- content -->
    <div class="">
      <Row>
        <Col style="width: 100%;">
          <!-- 输入sku开始分拣 -->
          <div class="skuInput">
            {{ $t('key1003672') }}
            <Input
              v-model.trim="skuInput"
              autofocus
              :disabled="packageProcessStep === 3"
              :element-id="'skuInputt'"
              ref="skuInput"
              style="width:300px;marginLeft:15px;"
              @on-enter="clearSku();skuStart()">
            </Input>
            <Checkbox v-if="packageGoodsType !== 'MM'" style="marginLeft:10px; display: inline-block;" v-model="isEachPiece">{{ $t('key1003236') }}</Checkbox>
          </div>
        </Col>
      </Row>
      <Row>
        <Col class="packProcessCol">
          <div class="packProcess">
            <Steps :current="packageProcessStep">
              <Step :title="$t('key1003745')" icon="ios-person"></Step>
              <Step :title="$t('key1003746')" icon="ios-person"></Step>
              <Step :title="$t('key1003747')" icon="ios-person"></Step>
              <Step :title="$t('key1003748')" icon="ios-person"></Step>
              <Step :title="$t('key1003749')" icon="ios-person"></Step>
            </Steps>
          </div>
        </Col>
      </Row>
      <Row justify="center" align="middle" type="flex" class="bar">
        <Col :span="5">
          <Row>
            <Col :span="8">{{ $t('key1003756') }}</Col>
            <Col :span="14">{{ packageCode }}</Col>
          </Row>
        </Col>
        <Col :span="5">
          <Row>
            <Col :span="8">{{ $t('key1003374') }}</Col>
            <Col :span="14">{{ labelTypeText }}</Col>
          </Row>
        </Col>
        <Col :span="5" offset="1">
          <Row justify="center" align="middle" type="flex" v-if="pickingGoodsWeighting && reviewPackaging">
            <Col :span="4">{{ $t('key1003037') }}</Col>
            <Col :span="19">
              <Input
                ref="weightInput"
                v-model.trim="packageWeight.weight"
                style="width:60px;marginLeft:5px;"
                @on-enter="weighting"
                :disabled="weightStatus"></Input> <Select
              v-model="packageWeight.weightUnit"
              style="width:60px;marginLeft:5px;"
              :disabled="weightStatus"><Option
              v-for="item in weightUnitList"
              filterable
              :key="item.value"
              :value="item.value">{{ item.name }}</Option></Select>
            </Col>
          </Row>
        </Col>
        <!--<Col :span="12" offset="1">
          <Row justify="center" align="middle" type="flex" v-if="reviewPackaging">
            <Col :span="3">选择包材：</Col>
            <Col :span="21" style="overflow:hidden;white-space: nowrap;">
              <Select
                v-model="wrappings.value"
                label-in-value
                @on-change="wrappingsChange"
                style="width:100px;marginLeft:5px;"
                transfer><Option
                v-for="item in wrappingsList"
                :key="item.value"
                :value="item.packageMaterialId">{{ item.packageMaterialName }}</Option></Select>
              <span style="marginLeft:15px;" v-if="wrappings.value">包材尺寸(cm):{{ showSize }}</span>
              <span style="marginLeft:15px;" v-if="wrappings.value">包材重量(g):{{ showWeight }}</span>
              <span style="marginLeft:15px;" v-if="wrappings.value">包材成本(￥):{{ showCost }}</span>
              <span style="marginLeft:15px;" v-if="wrappings.value">备注:{{ showDesc }}</span>
            </Col>
          </Row>
        </Col>-->
      </Row>
      <div class="scanPackageShow">
        <Row justify="center" align="middle" type="flex">
          <Col span="16">
            <!-- 扫描货品信息 -->
            <div class="skuBox">
              <div class="orderTable normalTop">
                <Table
                  border
                  :loading="tableLoading"
                  :height="550"
                  :columns="currentPackageColumn"
                  :data="currentPackageData"></Table>
              </div>
              <div>
                <a href="#" @click="clearScan()" class="cleanScanLink">{{ $t('key1003757') }}</a>
              </div>
            </div>
          </Col>
          <!-- 货品信息 -->
          <Col span="8">
            <div v-show="showSku">
              <!-- 货品信息 -->
              <div class="productInfo">
                <Row justify="center" align="middle" type="flex">
                  <Col>
                    <Tooltip placement="left" theme="light" :transfer="true" transfer-class-name="set_bigimg_style">
                      <img class="img_styles" :src="setImgPath(productImg)" @error="setErrorImg($event)" alt=""/>
                      <template #content>
                        <img :ref="'thumbnailImg'" @load="handlerOnloadImg('thumbnailImg')"
                          :src="setImgPath(productImg)" @error="setErrorImg($event)" alt=""/>
                      </template>
                    </Tooltip>
                  </Col>
                </Row>
                <Row justify="center" align="middle" type="flex">
                  <div style="fontSize:22px;marginTop:20px;">
                    SKU：<span style="color:#008000;">{{ sku }}</span>
                  </div>
                </Row>
                <Row justify="center" align="middle" type="flex">
                  <div style="fontSize:22px;marginTop:20px;">
                    {{ $t('key1001705') }}<span style="color:#008000;">{{ title }}</span>
                  </div>
                </Row>
                <!--共{{basketNum}}个拣货篮-->
                <Card dis-hover style="margin:15px 10px 0 10px;">
                  {{ $t('key1001714') }}{{ basketNum }}{{ $t('key1005845') }}
                  <div style="marginTop: 10px;overflow: auto;">
                    {{ basketList }}
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row class="bar">
        <Col>
          {{ $t('key1003758') }}
          <Button style="marginLeft:20px;" @click="printSelect">{{ $t('key1005846') }}</Button>
        </Col>
      </Row>
      <div class="orderTable">
        <Table
          border
          :loading="tableLoading"
          :height="550"
          :columns="printedPackageColumn"
          :data="printedPackageData"
          @on-selection-change="changeData"></Table>
      </div>
    </div>
    <!-- sku输入异常 -->
    <Modal v-if="warnModalStatus" v-model="warnStatus" :title="$t('key1003750')" width="500">
      <!--一个隐藏的input，用于回车时，关闭弹窗-->
      <Input :element-id="'skuAbnormal'" ref="skuAbnormal" @on-enter="hideSkuModal"
        style="opacity: 0;position: absolute;top: 50px;left: 0; z-index: -10;">
      </Input>
      <div style="padding:10px 20px;">
        <Row>
          <Col span="6">
            <div>
              <Icon type="md-information-circle" color="#2b85e4" style="font-size:50px"></Icon>
            </div>
          </Col>
          <Col
            span="16"
            v-if="findWarnStatus"
            style="font-size:16px">{{ $t('key1003751').replace('{warnSku}', warnSku) }}
          </Col>
          <Col span="16" v-if="warnPrintStatus" style="font-size:16px">{{ $t('key1003759') }}</Col>
          <Col span="16" v-if="warnMatchStatus" style="font-size:16px">
            <div>{{ $t('key1003760') }}</div>
            <div style="marginTop:3px;">
              {{ $t('key1003761') }}
            </div>
            <div style="marginBottom:15px;">
              {{ $t('key1003762') }}
            </div>
          </Col>
          <Col span="16" v-if="errorCode === 116104" style="font-size:16px">{{ $t('key1003763') }}</Col>
          <Col span="16" v-if="errorCode === 116101" style="font-size:16px">{{ $t('key1003764') }}</Col>
          <Col span="16" v-if="errorCode === 116102" style="font-size:16px">{{ $t('key1003765') }}</Col>
          <Col span="16" v-if="errorCode === 116114" style="font-size:16px">{{ $t('key1003766') }}</Col>
          <Col span="16" v-if="errorCode === 116113" style="font-size:16px">{{ $t('key1003767') }}</Col>
          <Col span="16" v-if="errorCode === 116118" style="font-size:16px">{{ $t('key1003768') }}</Col>
        </Row>
      </div>
      <template #footer>
        <Button style="marginRight:210px;" @click="cancel">{{ $t('key1000588') }}</Button>
      </template>
    </Modal>
    <!-- 结束作业 -->
    <endWarnModal
      ref="endWarnModal"
      :pickingGoodsNo="pickingGoodsNo"
      :pickingGoodsId="pickingGoodsId"
      :currentPackageData="currentPackageData">
    </endWarnModal>
    <!--订单取消提示-->
    <orderCancelPromptModal ref="orderCancelPrompt" @updateData="initFun"></orderCancelPromptModal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import endWarnModal from './endWarnModal';
import {getErpconfig} from '@/utils/user';
import orderCancelPromptModal from "@/components/common/orderCancelPromptModal";
import {downloadControl, getUserInfoByUserIdsData, supplierVerifyPicking, qs} from "@/utils/common";

export default {
  mixins: [Mixin],
  components: {
    endWarnModal,
    orderCancelPromptModal
  },
  data() {
    var self = this;
    return {
      reviewPackaging: true,
      errorCode: 0,
      scanSkuPackageId: '',
      filenodeViewTargetUrl: localStorage.getItem('imgUrlPrefix'),
      pickingGoodsNo: '',
      pickingGoodsId: '',
      packageGoodsTypeName: '',
      packageGoodsType: '',
      pickingGoodsWeighting: null, // 是否称重
      labelTypeText: '',
      packageCode: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003769'),
      labelPath: '', // 该包裹的打印pdf地址
      productImg: null, // 当前货品的图片
      title: '', // 当前货品名称
      variations: '', // 当前货品规格
      quantity: null, // 当前货品应发数量
      packageProcessStep: 0,
      pickData: [], // 拣货单数据
      sku: null, // 输入sku
      skuInput: null, // 输入的sku
      warnSku: null, // 异常sku
      warnStatus: false, // 控制是否显示异常警告
      findWarnStatus: false, // 查询sku警告
      warnPrintStatus: false, // 控制是否显示待打印警告
      warnMatchStatus: false, // 控制是否显示匹配异常错误
      warnModalStatus: false, // 是否生成模态框
      printedPackageData: [],
      scanSkuParams: {
        pickingGoodsId: null,
        packageId: null,
        skus: []
      },
      printedPackageColumn: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        }, {
          title: 'NO.',
          width: 80,
          align: 'center',
          key: 'index',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000131'),
          key: 'packageCode',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003770'),
          key: 'merchantShippingMethodId',
          align: 'center',
          render: (h, params) => {
            return h('div', [h('div', params.row.carrierName), h('div', params.row.carrierShippingMethodName)]);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002445'),
          key: 'isPrinted',
          align: 'center',
          render: (h, params) => {
            if (params.row.isPrinted) {
              return h('Icon', {
                props: {
                  type: 'md-checkmark-circle',
                  size: '20'
                },
                style: {
                  color: '#008000'
                }
              });
            }
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002767'),
          key: 'trackingNumber',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003771'),
          key: 'sku',
          align: 'center',
          render: (h, params) => {
            let pos = [];
            if (params.row.skus) {
              params.row.skus.forEach((n, i) => {
                pos.push(h('div', n.sku + ' * ' + n.quantity));
              });
              return h('div', pos);
            }
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005847'),
          key: 'materialId',
          align: 'center',
          render: (h, params) => {
            let pos = null;
            if (this.wrappingsList) {
              this.wrappingsList.forEach((n, i) => {
                if (n.packageMaterialId === params.row.materialId) {
                  pos = n.packageMaterialName;
                }
              });
              return h('div', pos);
            }
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000691'),
          key: 'totalWeight',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000278'),
          key: 'operation',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: params.row.isPrinted ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005848') : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002674'),
                    show: true,
                    clickFn: () => {
                      let falg = params.row.isPrinted === 1;
                      this.printStatus = true;
                      this.packageIds = [params.row.packageId];
                      this.printObj = params.row;
                      document.getElementById('skuInputt').focus();
                      self.print(params.row.labelPath, params.row.packageCode, !falg);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      currentPackageData: [],
      currentPackageColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          key: 'index',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003774'),
          key: 'title',
          align: 'center',
          render: (h, params) => {
            if (params.row.variations) {
              return h('span', params.row.name + ' / ' + params.row.variations);
            } else {
              return h('span', params.row.name);
            }
          }
        }, {
          title: 'SKU',
          key: 'sku',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              style: {
                color: '#008000'
              }
            }, params.row.sku);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003701'),
          key: 'quantity',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003728'),
          key: 'scanQuantity',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('InputNumber', {
                props: {
                  value: params.row.scanQuantity
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  input: value => {
                    params.row.scanQuantity = value;
                    if (!params.row.scanQuantityInputFocus) {
                      self.currentPackageData[params.index].scanQuantity = params.row.scanQuantity;
                    }
                  },
                  'on-focus': () => {
                    params.row.scanQuantityInputFocus = true;
                  },
                  'on-blur': () => {
                    params.row.scanQuantityInputFocus = false;
                    self.currentPackageData[params.index].scanQuantity = params.row.scanQuantity;
                  }
                }
              })
            ]);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003775'),
          key: 'check',
          align: 'center',
          render: (h, params) => {
            return self.currentPackageData[params.index].check ? h('span', {
              attrs: {
                class: 'icon iconfont greenColor cursor'
              },
              style: {
                margin: '0 10px'
              }
            }, '') : '';
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000091'),
          key: 'operation',
          align: 'center',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'icon iconfont icon-shanchu cursor redColor'
              },
              style: {
                margin: '0 10px',
                cursor: 'pointer',
                color: 'red'
              },
              on: {
                click: () => {
                  if (self.packageGoodsType !== 'MM') {
                    // 单品
                    self.clearScan();
                  } else {
                    self.currentPackageData.splice(params.index, 1);
                  }
                }
              }
            });
          }
        }
      ],
      showSku: true,
      packageWeight: {
        // 重量
        weightUnit: 0,
        weight: null
      },
      weightUnitList: [
        {
          name: 'g',
          value: 0
        }, {
          name: 'kg',
          value: 1
        }
      ],
      weightStatus: true, // 未完成包装前不能称重
      wrappings: {
        name: null,
        value: null
      }, // 包材
      wrappingsList: null, // 包材列表
      showSize: null, // 选择后展示的包材尺寸
      showWeight: null,
      showCost: null,
      showDesc: null,
      userName: null, // 获取操作员name
      basketNum: 0, // 拣货篮
      basketList: null,
      isEachPiece: true,
      printList: [],
      packageIds: [],
      printStatus: false,
      printObj: null,
      clearTime: null,
      breadcrumbList: [
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000018')},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003685')},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005849'), path: '/wms/packWorking'},
        {name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003667')}
      ],
    };
  },
  methods: {
    clearScan() {
      // 清空已扫描货品并重新扫描
      let v = this;
      clearTimeout(v.clearTime);
      if (v.scanSkuParams.packageId) {
        // 已经识别了是哪个包裹
        v.axios.put(api.put_wmsPickingGoods_packingClearScan + '?pickingGoodsId=' + v.pickingGoodsId + '&packageId=' + v.scanSkuParams.packageId).then(response => {
          if (response.data.code === 0) {
            v.currentPackageData = [];
            v.resetPackageParam();
          }
        });
      } else {
        // 还没找到包裹信息
        v.currentPackageData = [];
      }
    },
    weighting() {
      // 称重
      let v = this;
      v.packageWeight.packageId = v.scanSkuPackageId;
      v.axios.put(api.put_wmsPickingGoods_packingWeighting, JSON.stringify(v.packageWeight)).then(response => {
        if (response.data.code === 0) {
          // 修改这个包裹已打印列表中的重量
          v.printedPackageData[v.printedPackageData.length - 1].weight = v.packageWeight.weight;
          // 称重完进行下一个包裹sku扫描
          v.resetPackageParam();
          v.getScanningPickingGoods();
        }
      });
    },
    clearSku() {
      this.sku = this.skuInput;
      this.skuInput = '';
      document.getElementById('skuInputt').blur();
    },
    skuStart() {
      // 输入sku开始分拣
      let v = this;
      clearTimeout(v.clearTime);
      v.skuInput = '';
      let skuLocal = '';
      if (v.packageProcessStep === 3) {
        // 当前有待打印的，不允许sku
        v.warnPrintStatus = true;
        v.warnStatus = true;
        v.warnModalStatus = true;
        document.getElementById('skuInputt').focus();
        return;
      }
      v.warnStatus = false; // 确认警告框是隐藏的
      // 输入sku后，先判断是否有效
      let status = false;
      if (v.sku && v.pickData.length) {
        v.pickData.forEach((n, i) => {
          if (v.sku === n.sku || v.sku === n.upc) {
            status = true;
            skuLocal = n.sku;
            v.productImg = n.pictureUrl;
            v.title = n.title;
            v.variations = n.variations;
            // v.quantity = n.quantity;
          }
        });
        v.printedPackageData.forEach((m, t) => {
          if (m.skus && !m.isPrinted) {
            m.skus.forEach((s, p) => {
              if (s.sku === v.sku || s.upc === v.sku) {
                v.quantity = s.quantity;
              }
            });
          }
        });
      }
      if (!status && v.sku) {
        v.warnSku = v.sku;
        v.warnStatus = true;
        v.findWarnStatus = true;
        v.warnModalStatus = true;
        document.getElementById('skuInputt').focus();
        return;
      }
      // 单品时，且没勾选逐件扫描的情况下
      if (!v.isEachPiece && v.packageGoodsType !== 'MM') {
        // 加sku，或者数量
        if (v.sku) {
          let addFlag = true;
          v.currentPackageData.forEach((n, i) => {
            if (n.sku === v.sku || n.upc === v.sku) {
              addFlag = false;
            }
          });
          if (addFlag) {
            v.currentPackageData.push({
              sku: skuLocal,
              quantity: v.quantity,
              scanQuantity: 1,
              check: false,
              name: v.title,
              variations: v.variations
            });
          }
        }
      } else {
        // 加sku，或者数量
        let valid = false;
        if (v.sku) {
          let addFlag = true;
          v.currentPackageData.forEach((n, i) => {
            if (n.sku === v.sku || n.upc === v.sku) {
              n.scanQuantity += 1;
              addFlag = false;
            }
          });
          if (addFlag) {
            v.currentPackageData.push({
              sku: skuLocal,
              quantity: v.quantity,
              scanQuantity: 1,
              check: false,
              name: v.title,
              variations: v.variations
            });
          }
        }
        if (v.packageGoodsType === 'SS' && v.isEachPiece) {
          v.currentPackageData.forEach((n, i) => {
            if (n.scanQuantity === n.quantity) {
              valid = true;
            }
          });
          if (valid) {
            // 最后一个
            v.scanSku();
            return;
          }
        }
      }
      // 判断当前是否有未打印的，如果有不允许扫描，给出提示、
      // 判断拣货单类型为SS（单品）,有值调接口
      if (v.packageGoodsType === 'SS') {
        if (v.sku && v.currentPackageData.length === 1 && v.currentPackageData[0].scanQuantity === 1) {
          v.scanSku();
          return;
        } else if (!v.sku && v.currentPackageData.length) {
          v.scanSku();
          return;
        }
      }
      // TWMS-1658(这里不再有单品多件和单品单件的区别了，只有单品和多品，所以这段逻辑暂时无用)
      // 判断拣货单为SM（单品多件），第一次调接口，空值回车调接口
      // if (v.packageGoodsType === 'SM') {
      //   if (v.sku && v.currentPackageData.length === 1) {
      //     v.scanSku();
      //   }
      //   if (!v.sku && v.currentPackageData.length) {
      //     v.scanSku();
      //   }
      // }
      // 判断拣货单为MM（多品），空值回车调接口
      if (v.packageGoodsType === 'MM' && !v.sku) {
        v.scanSku();
        return;
      }
      document.getElementById('skuInputt').focus();
    },
    scanSku() {
      // 调扫描sku匹配包裹的接口
      let v = this;
      v.printStatus = false;
      v.scanSkuParams.pickingGoodsId = v.pickingGoodsId;
      let skus = [];
      v.currentPackageData.forEach((m, t) => {
        skus.push({
          sku: m.sku,
          quantity: m.scanQuantity
        });
      });
      v.scanSkuParams.skus = skus;
      v.scanSkuParams.materialId = v.wrappings.value;
      // 处理是否逐件扫描
      if (v.packageGoodsType !== 'MM') {
        if (v.isEachPiece) {
          v.scanSkuParams.isEachPiece = 0;
        } else {
          v.scanSkuParams.isEachPiece = 1;
        }
      } else {
        v.scanSkuParams.isEachPiece = 0;
      }
      v.axios.post(api.post_wmsPickingGoods_packingScanSku, JSON.stringify(v.scanSkuParams)).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (!v.isEachPiece && data.pickingGoodsSkus) {
            // 单品非逐件扫描时，拿接口返回数量
            v.currentPackageData.forEach((n, i) => {
              data.pickingGoodsSkus.forEach((m, t) => {
                if (n.sku === m.sku) {
                  n.scanQuantity = m.quantity;
                }
              });
            });
          }
          if (!v.scanSkuParams.packageId) {
            v.scanSkuParams.packageId = data.packageId;
          }
          // v.packageIds = [data.packageId];
          // v.scanSkuParams.packageId = data.packageId;
          v.packageCode = data.packageCode;
          v.trackingNumber = data.trackingNumber;
          v.labelPath = data.labelPath;
          // if (!v.pickingGoodsWeighting) {//不称重
          v.packageWeight.weight = data.totalWeight; // 打印后才称重的
          // }
          if (data.pickingGoodsSkus !== null && data.pickingGoodsSkus.length > 0) {
            // v.currentPackageData = unprintedPackage.pickingGoodsSkus;
            v.currentPackageData.forEach((n, i) => {
              n.check = false;
              data.pickingGoodsSkus.forEach((p, i) => {
                if (n.sku === p.sku) {
                  n.quantity = p.quantity;
                  n.check = n.scanQuantity === n.quantity;
                  n.name = p.name;
                  n.variations = p.variations;
                }
              });
            });
          }
          if (data.isMatch) {
            // 匹配完成了，调打印，打印成功调更新包装完成接口,并将包裹信息放置已打印的包裹列表中
            v.matchFinish();
          } else {
            document.getElementById('skuInputt').focus();
          }
          if (!data.isMatch && data.packageId === null) {
            // 匹配失败，提示
            v.warnMatchStatus = true;
            v.warnStatus = true;
            v.warnModalStatus = true;
            document.getElementById('skuInputt').focus();
          }
        } else {
          v.warnStatus = true;
          v.warnModalStatus = true;
          // document.getElementById('skuInputt').focus();
          if (response.data.code === 116104) {
            // 拣货单信息不存在
            v.errorCode = 116104;
          } else if (response.data.code === 116101) {
            // 拣货复核扫描的出库单不存在
            v.errorCode = 116101;
          } else if (response.data.code === 116102) {
            // 拣货复核扫描出库单已作废
            v.errorCode = 116102;
          } else if (response.data.code === 116114) {
            // SKU未匹配合适出库单
            v.errorCode = 116114;
          } else if (response.data.code === 116113) {
            // 拣货复核已结束
            v.errorCode = 116113;
          } else if (response.data.code === 116118) {
            // 存在未完成包装的出库单
            v.errorCode = 116118;
          }
        }
      });
    },
    matchFinish() {
      let v = this;
      // 调打印
      v.packageProcessStep = 3;
      v.print(v.labelPath, v.packageCode, true);
    },
    resetPackageParam() {
      let v = this;
      v.scanSkuParams.packageId = null;
      v.packageProcessStep = 0;
      v.weightStatus = true;
      v.packageCode = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003769');
    },
    getPickingList() {
      // 获取拣货单列表
      let v = this;
      let pos = [];
      if (v.pickingGoodsId) {
        v.axios.get(api.get_packageGoods_queryByPickingGoodsId + v.pickingGoodsId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            data.sortingPackageGoodsResult.forEach((n, i) => {
              pos.push({
                pictureUrl: n.pictureUrl,
                productGoodsId: n.productGoodsId,
                quantity: n.quantity,
                sku: n.sku,
                upc: n.upc,
                title: n.title,
                variations: n.variations,
                haveNum: 0 // 拣货单已分拣货品数量
              });
            });
            v.pickData = pos;
          }
        });
      }
    },
    // 获取拣货复核页面基本信息
    getScanningPickingGoods() {
      let v = this;
      v.axios.get(api.get_wmsPickingGoods_getScanningPickingGoods).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let pos = [];
            let merchantShippingMethodIdPos = [];
            v.labelTypeList.map((item) => {
              if (item.value === data.labelType) {
                if (item.value === 'null') {
                  v.labelTypeText = '-';
                } else {
                  v.labelTypeText = item.title;
                }
              }
            });
            if (data.packages !== null && data.packages.length > 0) {
              data.packages.forEach((n, i) => {
                pos.push({
                  isPrinted: n.isPrinted,
                  packageId: n.packageId,
                  packageCode: n.packageCode,
                  carrierAccountId: n.carrierAccountId,
                  merchantCarrierId: n.merchantCarrierId,
                  merchantShippingMethodId: n.merchantShippingMethodId,
                  skus: v.handleMultilinguaTableFields(n.skus, ['name']),
                  totalWeight: n.totalWeight,
                  trackingNumber: n.trackingNumber,
                  labelPath: n.labelPath,
                  materialId: n.materialId,
                  carrierShippingMethodName: null,
                  carrierName: null
                });
                merchantShippingMethodIdPos.push(n.merchantShippingMethodId);
              });
              if (merchantShippingMethodIdPos.length > 0) {
                v.GetQueryCarrierShippingName(JSON.stringify(merchantShippingMethodIdPos)).then((response1) => {
                  if (response1.length > 0) {
                    response1.forEach((n, i) => {
                      pos.forEach((m, t) => {
                        if (m.merchantShippingMethodId === n.shippingMethodId) {
                          m.carrierName = n.carrierName;
                          m.carrierShippingMethodName = n.carrierShippingMethodName;
                        }
                      });
                    });
                  }
                }).then(() => {
                  v.printedPackageData = pos; // 需求改动，这个列表直接展示
                });
              }
            }
            // 拣货篮
            if (data.pickingGoodsBaskets) {
              v.basketNum = data.pickingGoodsBaskets.length;
              v.basketList = data.pickingGoodsBaskets.toString();
            }
            v.pickingGoodsNo = data.pickingGoodsNo;
            let pathlist = [];
            if (v.pickingGoodsNo) {
              pathlist.push({
                name: v.pickingGoodsNo
              });
              let arr = [...v.breadcrumbList, ...pathlist];
              v.breadcrumbList = v.uniqueFunc(arr, 'name');
            }
            v.pickingGoodsId = data.pickingGoodsId;
            const pickTypeObj = {
              SS: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000179'),
              SM: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000179'),
              MM: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000178')
            };
            v.packageGoodsTypeName = pickTypeObj[data.packageGoodsType];
            v.packageGoodsType = data.packageGoodsType;
            v.pickingGoodsWeighting = data.pickingGoodsWeighting;
            if (data.userId) {
              getUserInfoByUserIdsData([data.userId],v).then((userData) => {
                if (userData) {
                  v.userName = userData[data.userId].userName;
                }
              })
            }
            let unprintedPackage = data.unprintedPackage;
            if (unprintedPackage !== null) {
              v.currentPackageData = v.handleMultilinguaTableFields(unprintedPackage.pickingGoodsSkus, ['name']);
              v.currentPackageData.forEach((n, i) => {
                n.scanQuantity = n.quantity;
                n.check = true;
              });
              v.scanSkuParams.packageId = unprintedPackage.packageId;
              v.packageCode = unprintedPackage.packageCode;
              v.packageProcessStep = 3;
            }
            v.getPickingList();

            // 进入拣货复核时，校验所含出库单状态，若存在订单已取消状态的出库单，则弹窗提示
            supplierVerifyPicking(api.put_wmsPickingGoods_checkPickingGoods, [v.pickingGoodsNo]).then((data) => {
              if (data.length > 0) {
                v.$refs['orderCancelPrompt'].basicData = data;
                v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
              }
            });
          } else {
            v.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005850'));
            return false;
          }
        }
      });
    },
    // 获取分拣架设置列表
    getPackageSetting() {
      let v = this;
      let obj = {
        typeList: ['packing']
      };
      v.axios.post(api.get_packageSetting, obj).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data.length > 0) {
            data.map((item) => {
              if (item.paramKey === 'enablePickingReviewForPackaging') {
                v.reviewPackaging = item.paramValue === 'Y';
              }
            });
          }
        }
      });
    },
    cancel() {
      // 取消弹框
      let v = this;
      v.warnStatus = false;
      v.findWarnStatus = false;
      v.warnPrintStatus = false;
      v.warnMatchStatus = false;
      v.errorCode = 0;
      document.getElementById('skuInputt').focus();
    },
    wrappingsChange(valueLabel) {
      // 选择包材
      let v = this;
      v.wrappings.name = valueLabel.label;
      v.wrappings.value = valueLabel.value;
      if (valueLabel.value) {
        v.wrappingsList.forEach((n, i) => {
          if (n.packageMaterialId === valueLabel.value) {
            v.showSize = n.packageMaterialLength + 'x' + n.packageMaterialWidth + 'x' + n.packageMaterialHeight;
            v.showWeight = n.packageMaterialWeight;
            v.showCost = n.packageMaterialCost;
            v.showDesc = n.packageMaterialDescription;
          }
        });
      }
    },
    print(path, postId, flag, orderBy, callback) {
      // JSONP /printResult flag是否调接口
      let v = this;
      let originPostId = postId;
      postId = postId + parseInt(Math.random() * 100000000);
      if (path) {
        v.printHtmlUrl(path, postId).then(res => {
          if (res.printType === 'cainiao') {
            // 菜鸟打印完成
            v.finishPrint(callback);
            return;
          }
          let jsonObj = res;
          if (jsonObj.content) {
            jsonObj.content = encodeURIComponent(jsonObj.content);
          }
          let instance = v.axios.create({
            timeout: 3000,
            headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8'
            }
          });
          v.tableLoading = true;
          delete v.axios.defaults.headers.post['X-Requested-With'];
          instance.post('http://localhost:10099/print', qs(jsonObj)).then(response => {
            v.tableLoading = false;
            if (response.status === 200) {
              v.startPrintMonitor(jsonObj, originPostId, flag, orderBy, callback);
            }
          }).catch(() => {
            let fileName = '/tool/TongtoolPrinter.exe';
            downloadControl(fileName).then(()=> {
              v.tableLoading = false;
            });
          });
        });
      } else {
        v.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003169'));
        v.tableLoading = false;
        return false;
      }
    },
    startPrintMonitor(jsonObj, postId, flag, orderBy, callback) {
      let v = this;
      let obj = {
        packageIds: [postId]
      };
      if (orderBy) {
        obj.printOrderBy = orderBy;
      }
      // jsonObj.responseType = 1;
      delete jsonObj.content;
      delete v.axios.defaults.headers.post['X-Requested-With'];
      // instance.post('http://localhost:10099/printResult?' + Qs.stringify(jsonObj))
      v.axios.jsonp('http://localhost:10099/printResult?' + qs(jsonObj)).then(response => {
        console.log(typeof response.data);
        if (response.data === '0') {
          v.clearTime = setTimeout(function () {
            v.skuInput = null;
            v.startPrintMonitor(jsonObj, postId, flag, orderBy, callback);
          }, 500);
        } else if (response.data === '2') {
          document.getElementById('skuInputt').focus();
          v.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003698'));
        } else if (flag) {
          // 不是重复打印，需要调接口
          // let param = {};
          // param.pickingGoodsId = v.pickingGoodsId;
          // param.packageId = v.scanSkuParams.packageId;
          v.finishPrint(callback);
        }
      });
    },
    finishPrint(callback) {
      let v = this;
      let params = null;
      if (v.printStatus) {
        params = {
          packageIds: v.packageIds,
          pickingGoodsId: v.pickingGoodsId
        };
        // 判断需要不需要加重量和包材
        if (v.pickingGoodsWeighting && v.printObj.totalWeight === null) {
          if (v.printObj.materialId === null) {
            params = {
              packageIds: v.packageIds,
              pickingGoodsId: v.pickingGoodsId,
              weight: v.packageWeight.weight,
              weightUnit: v.packageWeight.weightUnit,
              materialId: v.wrappings.value
            };
          } else {
            params = {
              packageIds: v.packageIds,
              pickingGoodsId: v.pickingGoodsId,
              weight: v.packageWeight.weight,
              weightUnit: v.packageWeight.weightUnit
            };
          }
        } else {
          if (v.printObj.materialId === null) {
            params = {
              packageIds: v.packageIds,
              pickingGoodsId: v.pickingGoodsId,
              materialId: v.wrappings.value
            };
          }
        }
      } else {
        params = {
          packageIds: [v.scanSkuParams.packageId],
          pickingGoodsId: v.pickingGoodsId,
          materialId: v.wrappings.value
        };
      }
      v.axios.put(api.post_wmsPickingGoods_packageFinishPrint, params).then(response => {
        document.getElementById('skuInputt').focus();
        callback && callback();
        if (response.data.code === 0) {
          v.scanSkuPackageId = v.scanSkuParams.packageId;
          v.scanSkuParams.packageId = '';
          v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002449'));
          // 移动数据到已打印包裹
          // let skuAndNum = '';
          // v.currentPackageData.forEach((n, i) => {
          //   skuAndNum = skuAndNum + n.sku + '*' + n.quantity + '<br>';
          // });
          // v.printedPackageData.push({
          //   packageCode: v.packageCode,
          //   trackingNumber: v.trackingNumber,
          //   weight: v.packageWeight.weight,
          //   sku: skuAndNum,
          //   wrappings: v.wrappings.name,
          //   labelPath: v.labelPath
          // });
          // 这里不再需要移动数据到已打印，改为进入页面就会展示所有拣货单的出库单数据，所以这里只需改变列表的打印状态
          v.currentPackageData.forEach((n, i) => {
            v.printedPackageData.forEach((m, t) => {
              if (n.sku === m.sku) {
                m.isPrinted = 1;
              }
            });
          });
          v.packageProcessStep = 4;
          // 如果是不称重，则重置基本数据，开始下一个包裹扫描
          if (!v.pickingGoodsWeighting) {
            v.resetPackageParam();
          } else {
            v.weightStatus = false;
            v.$nextTick(function () {
              v.$refs.weightInput.focus();
            });
          }
          // 扫描的sku列表清空
          v.currentPackageData = [];
          // 刷新出库单列表数据
          v.getScanningPickingGoods();
          v.$nextTick(() => {
            document.getElementById('skuInputt').focus();
          });
        }
      });
    },
    finishWork() {
      // 结束作业 --> 结束作业时，校验所含出库单状态，若存在订单已取消状态的出库单，则弹窗提示
      let v = this;
      supplierVerifyPicking(api.put_wmsPickingGoods_checkPickingGoods, [v.pickingGoodsNo]).then((data) => {
        if (data.length > 0) {
          v.$refs['orderCancelPrompt'].basicData = data;
          v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
        } else {
          v.axios.put(api.put_wmsPickingGoods_preparFinishPacking + v.pickingGoodsId).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              v.$refs.endWarnModal.isFinishStatus = true;
              if (data.isFinish) {
                // 无异常
                v.$refs.endWarnModal.isFinish = true;
                v.$refs.endWarnModal.endStatus = true;
                v.$refs.endWarnModal.normalData();
              } else {
                // 有异常
                v.$refs.endWarnModal.isFinish = true;
                v.$refs.endWarnModal.endStatus = false;
                v.$refs.endWarnModal.abnormalData();
              }
            }
          });
        }
      });
    },
    quitWork() {
      // 退出当前作业
      let v = this;
      v.axios.put(api.put_wmsPickingGoods_quitPacking + v.pickingGoodsId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.$refs.endWarnModal.isFinishStatus = true;
          if (data.isFinish) {
            // 是否可以走结束流程, true正常false异常
            v.$refs.endWarnModal.isFinish = true;
            v.$refs.endWarnModal.endStatus = true;
            v.$refs.endWarnModal.normalData();
          } else {
            if (data.isSingle) {
              // true一个人 false多人
              v.$refs.endWarnModal.isFinish = true;
              v.$refs.endWarnModal.endStatus = false;
              v.$refs.endWarnModal.abnormalData();
            } else {
              if (data.isQuit) {
                v.$router.push('/wms/packWorking');
              }
            }
          }
        }
      });
    },
    getWrapper() {
      // 获取包材
      // let v = this;
      return new Promise(resolve => {
        resolve(true);
        // v.axios.get(api.get_allWrapper).then(response => {
        //   if (response.data.code === 0) {
        //     let data = response.data.datas;
        //     if (data.length) {
        //       v.wrappingsList = data;
        //       v.wrappings.value = data[0].packageMaterialId;
        //       v.wrappings.name = data[0].packageMaterialName;
        //       let size = data[0].packageMaterialLength + 'x' + data[0].packageMaterialWidth + 'x' + data[0].packageMaterialHeight;
        //       v.showSize = size;
        //       v.showWeight = data[0].packageMaterialWeight;
        //       v.showCost = data[0].packageMaterialCost;
        //       v.showDesc = data[0].packageMaterialDescription;
        //     }
        //     resolve(true);
        //   }
        // });
      });
    },
    changeData(val) {
      // 打印选中面单数据
      let v = this;
      v.printList = val;
    },
    printBatch(n, pos) {
      let v = this;
      if (n >= v.printList.length) {
        v.tableLoading = false;
        return pos;
      }
      // 过滤掉已经打印的数据
      let newList = v.printList.filter((item) => {
        return !item.isPrinted
      });
      if (newList.length > 0) {
        v.packageIds = [newList[n].packageId];
        v.printObj = newList[n];
        v.print(newList[n].labelPath, newList[n].packageCode, true, null, function () {
          v.printBatch(n + 1, pos);
        });
      } else {
        v.tableLoading = false;
        v.$refs['selection'].selectAll(false);
        v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005851'));
        return false;
      }
    },
    printSelect() {
      // 打印选中面单
      let v = this;
      document.getElementById('skuInputt').focus();
      if (v.printList.length) {
        v.tableLoading = true;
        v.printBatch(0, []);
        v.printStatus = true;
      } else {
        v.tableLoading = false;
        v.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001665')); // 请选择数据
      }
    },
    hideSkuModal() {
      this.cancel();
    },
    //初始化接口数据
    initFun(obj) {
      let v = this;
      // 若拣货单内移除取消的出库单后，无其他出库单，则弹窗提示，点击知道了或关闭弹窗则结束分拣，跳转至拣货复核页面
      if (obj) {
        if (obj.allDelete === 1) {
          v.$Modal.info({
            title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003696'),
            content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003697'),
            okText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000588'),
            onOk: () => {
              // v.finishWork();
              // v.$refs['endWarnModal'].finishPackWork();
              v.$router.push('/wms/packWorking');
            },
          });
        }
      } else {
        getErpconfig('');
        Promise.resolve(v.getWrapper()).then(result => {
          v.getScanningPickingGoods();
          v.getPackageSetting();
        });
      }
    }
  },
  created() {
    this.initFun();
  },
  watch: {
    // 监听sku异常的弹窗
    warnStatus: {
      handler(value) {
        let v = this;
        if (value) {
          v.$nextTick(() => {
            document.getElementById('skuAbnormal').focus();
          });
        } else {
          v.$nextTick(() => {
            document.getElementById('skuInputt').focus();
          });
        }
      },
      deep: true
    },
  }
};
</script>

<style lang="less" scoped>
.packingWork {
  .breadcrumb_box {
    padding: 12px 0 0 15px;
  }
}

.pickHeader {
  width: 99%;
  height: 40px;
  margin: 10px auto;
  line-height: 40px;
  background-color: rgba(247, 150, 70, 1);
}

.skuInput {
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 12px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(43, 120, 228, 1);
}

.skuBox {
  height: 457px;
  margin-top: 10px;
  padding-top: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid rgb(164, 197, 235);
}

.pickingGoods {
  width: 99%;
  margin: 10px auto;
}

.pickBox {
  width: 100px;
  height: 50px;
  margin-top: 10px;
  border: 1px solid #000;
}

.productBox {
  width: 300px;
  height: 170px;
  border: 1px solid #000;
  font-size: 24px;
  text-align: center;
  line-height: 170px;
  font-weight: bold;
}

.productInfo {
  background-color: rgb(252, 251, 225);

  .img_styles {
    vertical-align: middle;
    width: auto;
    max-width: 277px;
    height: 171px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 50px;
  }
}

.scanPackageShow {
  border: 1px solid rgb(215, 215, 215);
  margin-left: 10px;
  margin-right: 12px;
  background-color: rgb(252, 251, 225);
}

.warnBox {
  width: 80%;
  height: 400px;
  border: 1px solid #717274;
  margin-left: 5%;
  background-color: #fff;
}

.warnBtn {
  width: 200px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-left: 40%;
  background-color: #aaa;
}

.packProcess {
  width: 700px;
  height: 50px;
  margin: 10px auto;
}

.packProcessCol {
  width: 100%;
  border-style: solid;
  margin-left: 10px;
  margin-right: 12px;
  margin-top: 15px;
  border-width: 1px;
}

.bar {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 12px;
  padding: 15px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(43, 120, 228, 1);
}

.cleanScanLink {
  padding-right: 12px;
  float: right;
}

.orderTable {
  padding: 0 12px;
}
</style>
<style>

.packProcess .ivu-steps .ivu-steps-title {
  display: block;
}

.packProcess .ivu-steps .ivu-steps-custom .ivu-steps-title {
  margin-left: -20px;
}

.packProcess .ivu-steps-item {
  overflow: initial
}
</style>
